import Carousel from 'react-bootstrap/Carousel';

function CarouselGolden({imageList}) {
    return (
        <div className='d-flex justify-content-center'>
            <Carousel className='w-50' fade>
                {imageList.map((image) => (
                    <Carousel.Item>
                        <img loading='lazy' className="d-block w-100" style={{objectFit:"contain"}} src={image} alt="Elevage de golden retriever"/>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default CarouselGolden;