import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CarouselGolden from './CarouselGolden';

import video from './video/naisssance_Scapa2.mp4';
import video2 from './video/naisssance_Scapa3.mp4';
import video3 from './video/naissance_Scapa4.mp4';
import video4 from './video/naissance_Usher.mp4';
import video5 from './video/naissance_Usher2.mp4';
import video6 from './video/naissance_Usher3.mp4';

import './Births.css'

const images = require.context('./img/golden/naissance/naissance_scapa', true);
const imageList = images.keys().map(image => images(image));

const images2 = require.context('./img/golden/naissance/naissance_usher', true);
const imageList2 = images2.keys().map(image2 => images2(image2));

const Births = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Dernières naissances de chiots golden retriever</title>
        <meta
          name="description"
          content="Retrouvez l'actualité des dernières naissances de chiots golden retriever dans le Puy-de-dôme de l'élevage au travers de photos et de vidéos."
        />
      </Helmet>
      <h1 className='mb-3'>Carnet de naissances</h1>
      <Tabs
        defaultActiveKey="Scapa"
        id="naissances-tab"
        className='mb-3'
      >
        <Tab eventKey="Scapa" title="Scapa">
          <Row>
            <Col>
              <div className='d-flex justify-content-center mb-3 video-container'>
                <video width="auto" height="auto" autoPlay loop muted playsInline controls>
                  <source src={video} type="video/mp4"/>
                </video>
              </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-center mb-3 video-container'>
                <video width="auto" height="auto" autoPlay loop muted playsInline controls>
                  <source src={video2} type="video/mp4"/>
                </video>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='d-flex justify-content-center mb-3 video-container'>
                <video width="auto" height="auto" autoPlay loop muted playsInline controls>
                  <source src={video3} type="video/mp4"/>
                </video>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='d-flex justify-content-center'>
                <p>Vidéos de la portée de 12 chiots de <Link to='/nos-chiens' state={{name:'Scapa'}}>Scapa</Link></p>
              </div>
              <div className='d-flex justify-content-center'>
                <p>Pour info, je vous conseille d'acheter un doudou ikea enfant que l'on peut voir dans la vidéo ci-dessus, nous pourrons le frotter sur la mère avant le départ du chiot !</p>
              </div>
            </Col>
          </Row>
          <CarouselGolden imageList={imageList}/>
        </Tab>

        <Tab eventKey="Usher" title="Usher">
          <Row>
            <Col>
              <div className='d-flex justify-content-center mb-3 video-container'>
                <video width="auto" height="auto" autoPlay loop muted playsInline controls>
                  <source src={video4} type="video/mp4"/>
                </video>
              </div>
            </Col>
            <Col>
              <div className='d-flex justify-content-center mb-3 video-container'>
                <video width="auto" height="auto" autoPlay loop muted playsInline controls>
                  <source src={video5} type="video/mp4"/>
                </video>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='d-flex justify-content-center mb-3 video-container'>
                <video width="auto" height="auto" autoPlay loop muted playsInline controls>
                  <source src={video6} type="video/mp4"/>
                </video>
              </div>
            </Col>
          </Row>
          <CarouselGolden imageList={imageList2}/>
          <div className='d-flex justify-content-center mt-3'>
            <p>Photos de la portée de 7 chiots de <Link to='/nos-chiens' state={{name:'Usher'}}>Usher</Link></p>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Births;
